<template>
  <div class="box">
    <div class="title">
      <div class="topics_title_one">
        <div
          class="topics_title_two"
          :class="viewType == 1 ? 'zb_topics_title_select' : ''"
          @click="typeChange(1)"
        >
          我的话题
        </div>
        <div
          class="topics_title_two"
          :class="viewType == 2 ? 'zb_topics_title_select' : ''"
          @click="typeChange(2)"
        >
          社会事件
        </div>
        <el-input
          v-model="sreach"
          class="topics_title_three"
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
        ></el-input>
        <el-button class="topics_title_four" type="primary" @click="sreachData"
          >查询</el-button
        >
      </div>
      <el-button
        type="primary"
        @click="lookAdd"
        icon="el-icon-plus"
        v-show="viewType == 1"
        >新增话题</el-button
      >
    </div>
    <span
      class="topics_title_span"
      @click="$router.push('/topics/index/topicList')"
      v-show="viewType == 1"
      >更多话题（{{ count ? count : 0 }}）</span
    >
    <span class="topics_title_span" v-show="viewType == 2"></span>
    <div class="boxcontent">
      <div class="boxcontent-center" v-loading="loadingsing">
        <div v-if="dataList.length != 0" class="boxcontent-center-div">
          <div
            v-for="(val, inx) in dataList"
            :key="inx"
            class="center-div"
            v-show="
              !(
                viewType == 2 &&
                val.topicType == 2 &&
                !$store.state.user.scope[1]
              )
            "
          >
            <!-- ！！！有轮播-->
            <div v-if="val.topicType == 1">
              <div class="center-div-title">
                <div class="center-div-title-left">话题：境内+跟评</div>
                <div v-if="val.isUpdate == 1" class="center-div-title-right">
                  深度更新中…
                </div>
                <div
                  v-if="val.isPause == 1"
                  class="center-div-title-right-update"
                >
                  更新中…
                </div>
              </div>
              <div class="handel" @click="hangelPush(val)">
                <div class="center-div-title1">{{ val.topicName }}</div>
                <div class="center-div-time">
                  {{ val.startTime + "-" + "当前" }}
                </div>
                <div class="center-div-bac">
                  <div
                    style="border-right: 1px solid rgba(255, 255, 255, 0.26)"
                    class="center-div-bac-left"
                  >
                    <div class="center-div-bac-left-left">信息总量</div>
                    <div class="center-div-bac-left-right">
                      {{ val.totalInformation }}
                    </div>
                  </div>
                  <div style="padding-left: 25px" class="center-div-bac-left">
                    <div class="center-div-bac-left-left">评论总量</div>
                    <div class="center-div-bac-left-right">
                      {{ val.totalComments }}
                    </div>
                  </div>
                </div>
                <el-carousel
                  :initial-index="1"
                  arrow="never"
                  trigger="click"
                  height="222px"
                  v-if="val.infoList && val.infoList.length > 0"
                >
                  <el-carousel-item>
                    <div
                      style="
                        font-weight: 500;
                        padding: 0 24px;
                        color: #999999;
                        margin: 16px 0;
                      "
                    >
                      信息分布
                    </div>
                    <ul class="center-div-tab">
                      <li v-for="(it, inx) in val.infoList" :key="inx">
                        <span>{{ it.showname }}</span>
                        <span>{{ it.totalComments }}</span>
                      </li>
                    </ul>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="messagePar" :id="'sg' + inx"></div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div
                      style="
                        font-weight: 500;
                        padding: 0 24px;
                        color: #999999;
                        margin: 16px 0;
                      "
                    >
                      信息分布
                    </div>
                    <ul class="center-div-tab">
                      <li v-for="(it, inx) in val.infoList" :key="inx">
                        <span>{{ it.showname }}</span>
                        <span>{{ it.totalComments }}</span>
                      </li>
                    </ul>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="messagePar" :id="'bar' + inx"></div>
                  </el-carousel-item>
                </el-carousel>
                <div class="analyse-container" v-else>
                  <div class="analyse-container-img">
                    <img src="../../assets/img/views/analyse-img.png" />
                    <p>暂无数据</p>
                  </div>
                </div>
                <div
                  v-if="val.commentList"
                  style="
                    font-weight: 500;
                    padding: 0 24px;
                    color: #999999;
                    margin: -5px 0 8px 0;
                  "
                >
                  评论分布
                </div>
                <ul class="center-div-tab1">
                  <li v-for="(item, index) in val.commentList" :key="index">
                    <img
                      v-if="item.showname == '视频'"
                      src="../../assets/img/views/shipin.png"
                    />
                    <img
                      v-else-if="item.showname == '电视'"
                      src="../../assets/img/views/dianshi.png"
                    />
                    <img
                      v-else-if="item.showname == '纸媒'"
                      src="../../assets/img/views/zhimei.png"
                    />
                    <img
                      v-else-if="item.showname == '网站'"
                      src="../../assets/img/views/wangzhan.png"
                    />
                    <img
                      v-else-if="item.showname == '微博'"
                      src="../../assets/img/views/weiboht.png"
                    />
                    <img
                      v-else-if="item.showname == '微信公众号'"
                      src="../../assets/img/views/weixinht.png"
                    />
                    <img
                      v-else-if="item.showname == '移动客户端'"
                      src="../../assets/img/views/yidong.png"
                    />
                    <img v-else src="../../assets/img/views/hudong.png" />
                    <div class="center-div-tab1-right">
                      {{ item.showname }}
                      <div>{{ item.totalComments }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="val.topicType == 0">
              <div class="center-div-title">
                <div class="center-div-title-left">话题：境内</div>
                <div v-if="val.isUpdate == 1" class="center-div-title-right">
                  深度更新中…
                </div>
                <div
                  v-if="val.isPause == 1"
                  class="center-div-title-right-update"
                >
                  更新中…
                </div>
              </div>
              <div class="handel" @click="hangelPush(val)">
                <div class="center-div-title1">{{ val.topicName }}</div>
                <div class="center-div-time">
                  {{ val.startTime + "-" + "当前" }}
                </div>
                <div class="center-div-bac">
                  <div class="center-div-bac-left">
                    <div class="center-div-bac-left-left">信息总量</div>
                    <div class="center-div-bac-left-right">
                      {{ val.totalInformation }}
                    </div>
                  </div>
                </div>
                <div
                  class="messagePar"
                  :id="'sg' + inx"
                  v-if="val.infoList && val.infoList.length > 0"
                ></div>
                <div
                  style="
                    font-weight: 500;
                    padding: 0 24px;
                    color: #999999;
                    margin: 16px 0;
                  "
                  v-if="val.infoList && val.infoList.length > 0"
                >
                  信息分布
                </div>
                <ul
                  class="center-div-tab"
                  v-if="val.infoList && val.infoList.length > 0"
                >
                  <li v-for="(va, inx) in val.infoList" :key="inx">
                    <span>{{ va.showname }}</span>
                    <span>{{ va.totalComments }}</span>
                  </li>
                </ul>
                <div class="analyse-container" v-else>
                  <div class="analyse-container-img">
                    <img src="../../assets/img/views/analyse-img.png" />
                    <p>暂无数据</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="val.topicType == 2">
              <div class="center-div-title">
                <div class="center-div-title-left">话题：境外</div>
                <div v-if="val.isUpdate == 1" class="center-div-title-right">
                  深度更新中…
                </div>
                <div
                  v-if="val.isPause == 1"
                  class="center-div-title-right-update"
                >
                  更新中…
                </div>
              </div>
              <div class="handel" @click="hangelPush(val)">
                <div class="center-div-title1">{{ val.topicName }}</div>
                <div class="center-div-time">
                  {{ val.startTime + "-" + "当前" }}
                </div>
                <div class="center-div-bac">
                  <div class="center-div-bac-left">
                    <div class="center-div-bac-left-left">信息总量</div>
                    <div class="center-div-bac-left-right">
                      {{ val.totalInformation }}
                    </div>
                  </div>
                </div>
                <div
                  class="messagePar"
                  :id="'sg' + inx"
                  v-if="val.infoList && val.infoList.length > 0"
                ></div>
                <div
                  style="
                    font-weight: 500;
                    padding: 0 24px;
                    color: #999999;
                    margin: 16px 0;
                  "
                  v-if="val.infoList && val.infoList.length > 0"
                >
                  信息分布
                </div>
                <ul
                  class="center-div-tab"
                  v-if="val.infoList && val.infoList.length > 0"
                >
                  <li v-for="(va, inx) in val.infoList" :key="inx">
                    <span>{{ va.showname }}</span>
                    <span>{{ va.totalComments }}</span>
                  </li>
                </ul>
                <div class="analyse-container" v-else>
                  <div class="analyse-container-img">
                    <img src="../../assets/img/views/analyse-img.png" />
                    <p>暂无数据</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="center-div-bottom" v-show="viewType == 1">
              <div
                @click="updata(val)"
                style="color: #02bc7c; border-left: none"
                :class="[val.isUpdate == 1 ? 'disabled' : '']"
              >
                <img
                  src="../../assets/img/views/monitoringScheme/fenzu10.png"
                />
                深度更新
              </div>
              <div @click="lookDialog(val)">
                <img
                  src="../../assets/img/views/monitoringScheme/fenzu11.png"
                />
                编辑
              </div>
              <div
                @click="
                  clientDeleteMsg(
                    {
                      topicId: val.id,
                      organId: $store.state.user.organizationId,
                      isDeepUpdate: 1,
                    },
                    0
                  )
                "
                style="color: #ea3342"
                v-if="val.childParentType == 1"
              >
                <img src="../../assets/img/views/guanbi.png" />
                关闭话题
              </div>
              <div @click="close(val)" style="color: #ea3342" v-else>
                <img src="../../assets/img/views/guanbi.png" />
                关闭话题
              </div>
            </div>
          </div>
        </div>
        <div class="analyse-container" v-else style="height: 700px">
          <div class="analyse-container-img">
            <img src="../../assets/img/views/analyse-img.png" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 话题新增 || 编辑 -->
    <el-dialog
      :title="title"
      :visible.sync="add.dialogVisible"
      width="700px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialogcenter">
        <ul class="dialogcenter-top">
          <li style="width: 100%">
            <div class="dialogcenter-top-left">话题类型</div>
            <el-radio-group v-model="add.radio">
              <el-radio :label="0" v-if="$store.state.user.scope[0]"
                >境内</el-radio
              >
              <el-radio
                :label="1"
                v-if="$store.state.user.scope[0] && $store.state.user.scope[2]"
                >境内+跟评</el-radio
              >
              <el-radio
                :label="2"
                v-if="$store.state.user.scope[0] && $store.state.user.scope[1]"
                >境外</el-radio
              >
            </el-radio-group>
          </li>
          <li>
            <div class="dialogcenter-top-left">话题名称</div>
            <el-input
              maxlength="30"
              clearable
              style="width: 100%"
              v-model.trim="add.topicName"
              placeholder="请输入话题名称"
            ></el-input>
          </li>
          <li>
            <div class="dialogcenter-top-left">开始时间</div>
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="add.startTime"
              type="datetime"
              placeholder="选择日期时间"
            ></el-date-picker>
          </li>
          <li style="width: 100%">
            <div class="dialogcenter-top-left">主关键字</div>
            <div class="dialogcenter-top-right">
              <el-input
                clearable
                v-model="add.mainKeyword"
                @keyup.enter.native="addUrl"
                placeholder="请添加主关键字，回车确认"
              ></el-input>
              <el-button
                style="margin-left: 16px"
                @click="addUrl"
                type="primary"
                >添 加</el-button
              >
            </div>
          </li>
          <li
            class="Listyleli"
            v-for="(val, inx) in add.mainKeywordList"
            :key="inx"
          >
            <div class="Listyle">
              <el-input
                class="Listyle-left"
                v-model="add.mainKeywordList[inx]"
                @blur="mainKeywordBlur(val, inx)"
                @focus="mainKeywordFocus(val, inx)"
              ></el-input>
              <img
                @click="deleteUrl(inx)"
                src="../../assets/img/views/monitoringScheme/XX.png"
                alt
              />
            </div>
          </li>
          <li style="width: 100%">
            <div class="dialogcenter-top-left">排除关键字</div>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请添加排除关键字"
              v-model="add.excludeKeyword"
            ></el-input>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain type="primary" @click="cancel">取 消</el-button>
        <el-button
          v-if="add.childParentType == 1"
          :disabled="saveDisabled"
          type="primary"
          @click="
            clientDeleteMsg(
              {
                topicId: id,
                organId: $store.state.user.organizationId,
              },
              1
            )
          "
          >保 存</el-button
        >
        <el-button
          v-else
          :disabled="saveDisabled"
          type="primary"
          @click="addPreservation"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <!-- 话题上限 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="提示"
      :visible.sync="onlineVisible"
      width="600px"
    >
      <span class="text-dialog"
        >当前活动话题已达上限！请您关闭话题后再操作。</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onlineVisible = false"
          >知道了</el-button
        >
      </span>
    </el-dialog>
    <!-- 删除 -->
    <deleteDialog ref="openDialog" @deleteFn="deleteFn" />
    <!-- 话题新建延迟 -->
    <el-dialog
      class="new-topics"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="newDialog"
      width="600px"
      style="margin-top: 20vh"
    >
      <span class="new-topics-tg" @click="newTopicsTg">跳过</span>
      <p class="new-topics-title">
        <span class="text-dh1">已</span>
        <span class="text-dh2">对</span>
        <span class="text-dh3">全</span>
        <span class="text-dh4">网</span>
        <span class="text-dh5">进</span>
        <span class="text-dh6">行</span>
        <span class="text-dh7">布</span>
        <span class="text-dh8">控</span>
        <span class="text-dh9">4</span>
        <span class="text-dh10">8</span>
        <span class="text-dh11">个</span>
        <span class="text-dh12">监</span>
        <span class="text-dh13">控</span>
        <span class="text-dh14">节</span>
        <span class="text-dh15">点</span>
      </p>
      <p class="new-topics-title">
        <span class="text-dh16">大</span>
        <span class="text-dh17">数</span>
        <span class="text-dh18">据</span>
        <span class="text-dh19">处</span>
        <span class="text-dh20">理</span>
        <span class="text-dh21">中</span>
        <span class="text-dh22">心</span>
        <span class="text-dh23">对</span>
        <span class="text-dh24">数</span>
        <span class="text-dh25">据</span>
        <span class="text-dh26">清</span>
        <span class="text-dh27">洗</span>
      </p>
      <p class="new-topics-title">
        <span class="text-dh28">A</span>
        <span class="text-dh29">I</span>
        <span class="text-dh30">算</span>
        <span class="text-dh31">法</span>
        <span class="text-dh32">模</span>
        <span class="text-dh33">型</span>
        <span class="text-dh34">对</span>
        <span class="text-dh35">数</span>
        <span class="text-dh36">据</span>
        <span class="text-dh37">分</span>
        <span class="text-dh38">析</span>
        <span class="text-dh39">处</span>
        <span class="text-dh40">理</span>
      </p>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from "echarts";
import {
  topicList,
  addPreservation,
  deletes,
  editTopic,
  keyWords,
  clientDeleteMsg,
} from "../../api/topic/topic";
import deleteDialog from "../../components/deleteDialog";
export default {
  data() {
    return {
      newDialog: false,
      loadingsing: false,
      onlineVisible: false,
      dataList: [],
      topicId: 0,
      id: 0,
      count: 0,
      saveDisabled: false, //禁用保存按钮
      title: "新增话题",
      // 新增话题时的对象
      add: {
        dialogVisible: false,
        // 预警提醒
        radio: null,
        radioChange: 0,
        // 话题名称
        topicName: "",
        // 改变后开始时间
        startTime: "",
        // 改变前的开始时间
        startTimeChang: "",
        // 主关键字
        mainKeyword: "",
        // 排除关键字
        excludeKeyword: "",
        excludeKeywordChange: "",
        // 存放关键字组小条条
        mainKeywordList: [],
      },
      // 境内 境外+跟评 单选
      radioArr: [],
      //用于对比关键字组数组的长度，来判断是否编辑过关键字
      contrastList: [],
      //展示类型选择
      viewType: 1,
      sreach: "",
      buttonClick: true,
      oldStr: "",
      oldInx: -1,
      time: -1,
    };
  },
  created() {
    this.topicList();
    this.topiCount();
  },
  mounted() {},
  methods: {
    //分享的话题脱离关联关系
    async clientDeleteMsg(data, type) {
      let res = await clientDeleteMsg(data);
      if (res.data.code == 200) {
        if (type == 1) {
          this.addPreservation();
        } else {
          this.topicList();
        }
      } else {
        this.$message.error(res.data.msg);
      }
    },
    //跳过话题等待
    newTopicsTg() {
      clearTimeout(this.time);
      this.newDialog = false;
      this.topicList();
    },
    //开始话题等待
    newTopicsKs() {
      this.newDialog = true;
      this.newTopicsFun();
    },
    //模拟进度
    newTopicsFun() {
      this.time = setTimeout(() => {
        clearTimeout(this.time);
        this.$message({
          message: "添加成功！",
          type: "success",
        });
        this.newDialog = false;
        this.topicList();
      }, 5000);
    },
    // 编辑时打开弹框
    lookDialog(val) {
      this.saveDisabled = false;
      this.oldInx = -1;
      this.title = "编辑";
      this.topicId = val.topicId;
      this.id = val.id;
      this.add.dialogVisible = true;
      this.add.radio = val.topicType;
      this.add.radioChange = val.topicType;
      this.add.topicName = val.topicName;
      this.add.startTime = val.startTime;
      this.add.startTimeChang = val.startTime;
      this.add.mainKeyword = "";
      this.add.mainKeywordList = val.mainKeyword.split(",");
      this.contrastList = val.mainKeyword.split(",");
      this.add.excludeKeyword = val.excludeKeyword;
      this.add.excludeKeywordChange = val.excludeKeyword;
      this.add.childParentType = val.childParentType;
    },
    //  新增话题
    lookAdd() {
      this.saveDisabled = false;
      this.oldInx = -1;
      this.title = "新增话题";
      if (this.dataList.length >= this.$store.state.user.topicLimit) {
        this.onlineVisible = true;
      } else {
        this.add = {
          dialogVisible: true,
          // 预警提醒
          radio: 0,
          // 话题名称
          topicName: "",
          // 开始时间
          startTime: "",
          // 主关键字
          mainKeyword: "",
          // 排除关键字
          excludeKeyword: "",
          mainKeywordList: [],
          upateFlag: null,
        };
      }
    },
    // 取消
    cancel() {
      this.add.dialogVisible = false;
    },
    // 删除
    async deleteList(val) {
      val.message = "此话题";
      this.$refs.openDialog.openDialog(val);
    },
    async deleteFn(item) {
      const res = await deletes({
        id: item.id,
      });
      if (res.data.code == 200) {
        this.$message({
          message: "删除成功!",
          type: "success",
        });
      } else {
        this.$message.error("删除失败！请稍后再试！");
      }
      this.topicList();
    },
    //主关键字添加url
    async addUrl() {
      this.add.mainKeyword = this.add.mainKeyword.trim();
      if (this.add.mainKeywordList.indexOf(this.add.mainKeyword) != -1) {
        return this.$message.warning("关键字已经存在！");
      }
      let res = await keyWords({
        organId: this.$store.state.user.organizationId,
        mainKeyword: this.add.mainKeyword,
      });
      if (res.data.code != 200) {
        this.$message.warning(
          "社会公共话题具有相同关键词，无需建立话题可直接使用，或联系业务人员协助建立话题"
        );
        return;
      }
      if (this.add.mainKeyword != "") {
        this.add.mainKeywordList.push(this.add.mainKeyword);
      }
      this.add.mainKeyword = "";
    },
    // 保存
    async addPreservation() {
      if (!this.$store.state.user.scope[0]) {
        return this.$message.warning("当前机构未开通话题话题权限!");
      }
      for (let i = 0; i < this.add.mainKeywordList.length; i++) {
        if (this.add.mainKeywordList[i] == this.add.excludeKeyword) {
          return this.$message.warning("排除关键字不能和主关键字一致！");
        }
      }
      if (this.add.topicName == "") {
        this.$message.warning("话题名称不能为空");
        return false;
      }
      if (this.add.startTime == null) {
        this.$message.warning("时间不能为空");
        return false;
      }
      if (this.add.startTime == "") {
        this.$message.warning("时间不能为空");
        return false;
      }
      if (this.add.mainKeywordList == "") {
        this.$message.warning("主关键字不能为空");
        return false;
      }
      if (
        this.oldInx != -1 &&
        this.add.mainKeywordList[this.oldInx] &&
        this.oldStr != this.add.mainKeywordList[this.oldInx]
      ) {
        let res = await keyWords({
          organId: this.$store.state.user.organizationId,
          mainKeyword: this.add.mainKeywordList[this.oldInx],
        });
        if (res.data.code != 200) {
          this.$message.warning(
            "社会公共话题具有相同关键词，无需建立话题可直接使用，或联系业务人员协助建立话题"
          );
          return false;
        }
      }
      // 判断关键字长度
      let Keyword = false;
      this.add.mainKeywordList.forEach((x) => {
        if (x.replace(/ /gi, "").length < 3) {
          Keyword = true;
        }
      });
      if (
        Keyword &&
        this.$store.state.user.organizationId != 429 &&
        this.$store.state.user.organizationId != 507 &&
        this.$store.state.user.organizationId != 440 &&
        this.$store.state.user.organizationId != 1326 && 
        this.$store.state.user.organizationId != 1771
      ) {
        this.$message.warning("输入的关键词太少，请联系业务人员帮助建立");
        return false;
      }
      let month = new Date(this.add.startTime).getTime();
      let oldMonth = new Date().setMonth(new Date().getMonth() - 6);
      if (month < oldMonth && this.$store.state.user.organizationId != 6420 && this.$store.state.user.organizationId != 1771) {
        this.$message.warning("输入时间太远，请联系业务人员帮助建立");
        return false;
      }
      let data = {
        topicType: this.add.radio,
        topicName: this.add.topicName,
        startTime: this.add.startTime,
        mainKeyword: this.add.mainKeywordList.join(","),
        excludeKeyword: this.add.excludeKeyword,
        organId: this.$store.state.user.organizationId,
      };
      if (this.title == "新增话题" || this.add.childParentType == 1) {
        this.addTopic(data);
      } else {
        this.editTopics(data);
      }
    },
    // 话题新增
    addTopic(data) {
      this.saveDisabled = true;
      Promise.all([this.topicList()]).then(() => {
        if (this.dataList.length >= this.$store.state.user.topicLimit) {
          this.onlineVisible = true;
          return;
        }
        Promise.all([addPreservation(data)]).then((res) => {
          if (this.title == "新增话题") {
            if (res[0].data.code == 200) {
              this.newTopicsKs();
            } else {
              this.$message.error(res[0].data.msg);
            }
          } else {
            if (res[0].data.code == 200) {
              this.$message({
                message: "编辑成功！",
                type: "success",
              });
              this.topicList();
            } else {
              this.$message.error(res[0].data.msg);
            }
          }
          this.add.dialogVisible = false;
          setTimeout(() => {
            this.saveDisabled = false;
          }, 500);
        });
      });
    },
    // 编辑话题
    async editTopics(data) {
      this.saveDisabled = true;
      let contrastList = this.contrastList.join(",");
      let keyWord = this.add.mainKeywordList.join(",");
      if (
        this.add.startTime != this.add.startTimeChang ||
        keyWord != contrastList ||
        this.add.radio != this.add.radioChange ||
        this.add.excludeKeyword != this.add.excludeKeywordChange
      ) {
        const res = await deletes({
          id: this.id,
        });
        if (res.data.code == 200) {
          this.addTopic(data);
        } else {
          this.$message.error(res.data.msg);
        }
      } else {
        data.topicId = this.topicId;
        data.userEdit = 1;
        data.updateKeyState = 1;
        const res1 = await editTopic(data);
        if (res1.data.code == 200) {
          this.$message({
            message: "编辑成功！",
            type: "success",
          });
          this.topicList();
        } else {
          this.$message.error(res1.data.msg);
        }
        this.add.dialogVisible = false;
        setTimeout(() => {
          this.saveDisabled = false;
        }, 500);
      }
    },
    // 深度更新按钮
    async updata(val) {
      let res = await editTopic({
        topicId: val.topicId,
        isUpdate: 1, //深度更新
        updateTypeId: val.isPause ? 1 : 0,
        isPause: 0, //更新
        organId: this.$store.state.user.organizationId,
        userEdit: 0,
        id: val.id,
      });
      if (res.data.code == 200) {
        this.$message.success("操作成功");
      }
      this.topicList();
    },
    //删除url
    deleteUrl(inx) {
      this.add.mainKeywordList.splice(inx, 1);
    },
    echarts1(i, infoList) {
      infoList.forEach((item) => {
        item.name = item.showname;
        item.value = item.totalComments;
      });
      var myChart = echarts.init(document.getElementById("sg" + i));
      if (document.getElementById("bar" + i)) {
        var myChart1 = echarts.init(document.getElementById("bar" + i));
      }
      let option = {
        color: [
          "#009CFC",
          "#11DAE9",
          "#21CA63",
          "#FFC75C",
          "#FF855B",
          "#FE6299",
          "#802EEC",
          "#2E59EC",
        ],
        label: {
          formatter: "{b}",
        },
        tooltip: {},
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: 70,
            minAngle: 10,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
            data: infoList,
          },
        ],
      };
      myChart.setOption(option);
      if (myChart1) {
        myChart1.setOption(option);
      }
    },
    // 关闭
    async close(val) {
      const res = await editTopic({
        organId: this.$store.state.user.organizationId,
        topicId: val.topicId,
        isUpdate: 0, //未深度更新
        userEdit: 0,
        isDeepUpdate: 1, //关闭
        isPause: 0, //未更新
        id: val.id,
      });
      if (res.data.code == 200) {
        this.$message({
          message: "关闭成功！",
          type: "success",
        });
      } else {
        this.$message.error("关闭失败！");
      }
      this.topicList();
      this.topiCount();
    },
    // 首页列表
    async topicList(str) {
      return new Promise((resolve) => {
        let data = {
          organId: this.$store.state.user.organizationId,
          isDeepUpdate: 0, //0未关闭 1关闭
        };
        if (str != undefined) {
          data.topicName = str;
        }
        this.loadingsing = true;
        this.dataList = [];
        this.$http({
          url: "api/topic/analysis/list",
          method: "post",
          data: data,
        }).then((res) => {
          if (res.data.rows) {
            this.dataList = res.data.rows;
            this.dataList.forEach((item, index) => {
              if (item.infoList && item.infoList.length > 0) {
                setTimeout(() => {
                  this.echarts1(index, item.infoList);
                }, 500);
              }
            });
          }
          this.loadingsing = false;
          resolve();
        });
      });
    },
    async topiCount() {
      const res = await topicList({
        organId: this.$store.state.user.organizationId,
        isDeepUpdate: 1, //0未关闭 1关闭
      });
      if (res != undefined) {
        this.count = res.data.total;
      }
    },
    hangelPush(val) {
      window.open(
        `#/topics/index/topicList/topicListDetails?id=${val.id}&topicId=${val.topicId}&type=index&topicType=${val.topicType}&isDeepUpdate=${val.isDeepUpdate}&isPause=${val.isPause}`
      );
    },
    async mainKeywordBlur(val, inx) {
      //主关键字失去焦点判断
      if (!val) {
        this.add.mainKeywordList.splice(inx, 1);
      } else {
        let res = await keyWords({
          organId: this.$store.state.user.organizationId,
          mainKeyword: val,
        });
        if (res.data.code != 200) {
          this.$message.warning(
            "社会公共话题具有相同关键词，无需建立话题可直接使用，或联系业务人员协助建立话题"
          );
          this.add.mainKeywordList[inx] = this.oldStr;
          this.$forceUpdate();
        }
      }
    },
    mainKeywordFocus(val, inx) {
      this.oldStr = val;
      this.oldInx = inx;
    },
    typeChange(num) {
      //切换话题类型
      this.viewType = num;
      switch (num) {
        case 1:
          this.topicList();
          break;
        case 2:
          this.getPubilc();
          break;
      }
    },
    getPubilc(str) {
      //获取公共话题数据
      let data = {
        organId: this.$store.state.user.organizationId,
        isDeepUpdate: 0,
      };
      if (str != undefined) {
        data.topicName = str;
      }
      this.loadingsing = true;
      this.dataList = [];
      this.$http({
        method: "post",
        url: "api/topic/analysis/listPublic",
        data: data,
      }).then((res) => {
        if (res.data.rows) {
          this.dataList = res.data.rows;
          this.dataList.forEach((item, index) => {
            if (item.infoList && item.infoList.length > 0) {
              setTimeout(() => {
                this.echarts1(index, item.infoList);
              }, 500);
            }
          });
        }
        this.loadingsing = false;
      });
    },
    sreachData() {
      //查询话题
      if (this.viewType == 1) {
        this.topicList(this.sreach);
      } else if (this.viewType == 2) {
        this.getPubilc(this.sreach);
      }
    },
  },
  components: {
    deleteDialog,
  },
};
</script>
<style lang="scss">
.box {
  .new-topics {
    .el-dialog__header {
      display: none;
    }
  }
}
</style>
<style lang="scss" scoped>
.box {
  min-height: 850px;
  background: url("../../assets/img/views/bg.png");

  .text-dialog {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #333333;
  }

  .title {
    display: flex;
    justify-content: space-between;
    padding: 20px 24px 15px;

    ::v-deep .el-button {
      height: 36px;
      background: linear-gradient(313deg, #2e59ec 0%, #1a91ff 100%);
      border-radius: 4px;
    }
  }

  .boxcontent {
    display: flex;
    align-items: center;
    justify-content: center;

    .boxcontent-center {
      min-height: 700px;
      display: flex;
      width: 1416px;
      overflow: hidden;
      margin: 0 50px;

      .handel {
        cursor: pointer;
      }

      .boxcontent-center-div {
        display: flex;
        flex-wrap: wrap;

        .center-div {
          width: 440px;
          height: 740px;
          background: #ffffff;
          box-shadow: 0px 1px 12px -3px rgba(0, 0, 0, 0.06);
          border-radius: 8px;
          margin-right: 20px;
          margin-bottom: 20px;
          padding: 16px 0;
          position: relative;

          .messagePar {
            width: 100%;
            height: 214px;
          }

          .center-div-title {
            margin: 0 24px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #d8d8d8;
            padding-bottom: 16px;

            .center-div-title-left {
              font-size: 14px;
              font-weight: bold;
              color: #333333;
            }

            .center-div-title-right {
              font-size: 14px;
              color: #02bc7c;
            }

            .center-div-title-right-update {
              color: #2e59ec;
              font-size: 14px;
            }

            .center-div-title-right-stop {
              font-size: 14px;
              color: #ff9000;
            }
          }

          .center-div-title1 {
            padding: 0 24px;
            margin: 16px 0;
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            line-height: 25px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          .center-div-time {
            margin: 0 24px;
            font-size: 14px;
            color: #999999;
            line-height: 20px;
          }

          .center-div-bac {
            margin: 0 24px;
            display: flex;
            align-items: center;
            padding: 16px 24px;
            width: 392px;
            height: 93px;
            border-radius: 4px;
            background-image: url("../../assets/img/views/monitoringScheme/topbac.png");
            background-repeat: no-repeat;
            background-size: 100%;
            margin-top: 16px;

            .center-div-bac-left {
              width: 50%;

              .center-div-bac-left-left {
                font-size: 14px;
                color: #ffffff;
                line-height: 20px;
              }

              .center-div-bac-left-right {
                font-size: 24px;
                font-weight: bold;
                color: #ffffff;
                line-height: 33px;
              }
            }
          }

          .center-div-tab {
            margin: 0 24px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
              width: 49%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 30px;
              background: #f9fafc;
              border-radius: 4px;
              padding: 0 16px;
              margin-bottom: 8px;
            }
          }

          .center-div-tab1 {
            margin: 0 24px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
              width: 120px;
              height: 48px;
              background: #f9fafc;
              border-radius: 4px;
              margin-bottom: 16px;
              display: flex;
              align-items: center;
              padding: 8px;

              img {
                width: 32px;
                height: 32px;
                margin-right: 8px;
              }

              .center-div-tab1-right {
                font-size: 10px;
                color: #999999;

                div {
                  font-size: 14px;
                  color: #333333;
                  width: 55px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }

          .center-div-bottom {
            display: flex;
            position: absolute;
            bottom: 0;
            width: 100%;

            div {
              width: 34%;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 14px 0;
              border-top: 1px solid #e4e6ec;
              border-left: 1px solid #e4e6ec;
              font-size: 14px;
              color: #333333;
              cursor: pointer;

              img {
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }

  .dialogcenter {
    .dialogcenter-top {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      li {
        width: 48%;
        margin-bottom: 24px;

        .dialogcenter-top-left {
          margin-bottom: 16px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }

        .dialogcenter-top-right {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .Listyle {
          height: 32px;
          // background: #f5f6fa;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // padding: 0 16px;

          // .Listyle-left {
          //   overflow: hidden;
          //   text-overflow: ellipsis;
          //   white-space: nowrap;
          // }

          img {
            padding: 0 28px 0 44px;
            cursor: pointer;
          }
        }
      }

      .Listyleli {
        width: 100%;
      }
    }
  }
}

.analyse-container {
  width: 100%;
  height: 214px;
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}

.analyse-container-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.topics_title_span {
  font-size: 15px;
  font-weight: bold;
  color: #2e59ec;
  cursor: pointer;
  margin-left: 24px;
}

.topics_title_one {
  display: flex;
}

.topics_title_two {
  font-weight: bold;
  font-size: 15px;
  height: 36px;
  line-height: 36px;
  border-radius: 5px;
  background-color: #fff;
  padding: 0 15px;
  box-sizing: content-box;
  flex-shrink: 0;
  margin-right: 20px;
  cursor: pointer;
  user-select: none;
}

.topics_title_three {
  margin-left: 40px;
}

.topics_title_four {
  margin-left: 20px;
}

.zb_topics_title_select {
  background-color: #2e59ec;
  color: white;
  pointer-events: none;
}

.new-topics {
  .new-topics-tg {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    font-size: 14px;
    color: #999999;
  }

  .new-topics-title {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
  }

  .new-topics-percentage {
    text-align: center;
    font-size: 16px;
    color: #2e59ec;
    margin-top: 15px;
  }
}

@keyframes dh1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.text-dh1 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
}

.text-dh2 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 0.1;
  animation-delay: 0.1s;
}

.text-dh3 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 0.2s;
}

.text-dh4 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 0.3s;
}

.text-dh5 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 0.4s;
}

.text-dh6 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 0.5s;
}

.text-dh7 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 0.6s;
}

.text-dh8 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 0.7s;
}

.text-dh9 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 0.8s;
}

.text-dh10 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 0.9s;
}

.text-dh11 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 1s;
}

.text-dh12 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 1.2s;
}

.text-dh13 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 1.3s;
}

.text-dh14 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 1.4s;
}

.text-dh15 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 1.5s;
}

.text-dh16 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 1.6s;
}

.text-dh17 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 1.7s;
}

.text-dh18 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 1.8s;
}

.text-dh19 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 1.9s;
}

.text-dh20 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 2s;
}

.text-dh21 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 2.1s;
}

.text-dh22 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 2.2s;
}

.text-dh23 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 2.3s;
}

.text-dh24 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 2.4s;
}

.text-dh25 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 2.5s;
}

.text-dh26 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 2.6s;
}

.text-dh27 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 2.7s;
}

.text-dh28 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 2.8s;
}

.text-dh29 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 2.9s;
}

.text-dh30 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 3s;
}

.text-dh31 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 3.1s;
}

.text-dh32 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 3.2s;
}

.text-dh33 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 3.3s;
}

.text-dh34 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 3.4s;
}

.text-dh35 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 3.5s;
}

.text-dh36 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 3.6s;
}

.text-dh37 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 3.7s;
}

.text-dh38 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 3.8s;
}

.text-dh39 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 3.9s;
}

.text-dh40 {
  display: inline-block;
  opacity: 0;
  animation: dh1 1s linear forwards;
  animation-delay: 4s;
}
</style>
